@import "../../utils/mixins.scss";

@mixin signUp_card_mobile_style($width, $padding) {
  background-color: #fff !important;
  width: $width !important;
  display: flex !important;
  flex-direction: column !important;
  padding: $padding !important;
  border-radius: 25px !important;
  box-shadow: 0 17px 24px 0 #007ebf42 !important;
  margin-bottom: 15px !important;
}

.Login {
  height: 100vh;
  // width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden !important;
  // margin-top: ;
  @media screen and (max-width: 821px) {
    width: 100vw;
    display: flex;
    justify-content: center;
  }
  &__signup {
    @include heading5;
    font-weight: 400;
  }
  &__Side-Image {
    // background-image: url(https://network-on.s3.ap-south-1.amazonaws.com/template/54994799281636541287522-onboardimage.png);
    max-height: 102vh !important;
    // background-size: 100% auto;
    // box-shadow: 0 60px rgba(0, 0.5) !important;

    background-position-x: center !important;
    // display: none;
    // background-repeat: no-repeat;
    @media screen and (max-width: 821px) {
      display: none;
    }
  }
  &__trial-heading-text {
    font-size: 18px !important;
    // margin-right: 10px !important;
    margin-top: -10px !important;
    margin-bottom: 10px !important;
    text-align: center;
    font-weight: 500 !important;
    width: 100%;
  }

  &__sign-up {
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    color: var(--primary-theme);
    float: right;
    margin-top: 13px;

    font-weight: 400;
    cursor: pointer;
    line-height: 24px;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-title {
      @include heading5;
      font-weight: 400;
      color: #261d56;
    }
  }

  &__google-img {
    height: 38px;
    width: 38px;
  }
  &__fb-img {
    height: 26px;
    width: 26px;
  }
  &__footer {
    min-height: 72px;
    display: flex;
    justify-content: space-between;
  }

  &__link {
    @include heading5;
    color: var(--primary-theme);
    float: right;
    font-weight: 400;
    cursor: pointer;
    line-height: 24px;
    &:hover:enabled {
      color: white;
      background-color: var(--secondary-theme);
    }
  }
  &__body {
    margin-top: 60px;
    flex: 1;
    background-color: var(--page-bg-color) !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 150px);
    // width: 100%;
    align-items: center;
    @media screen and (max-width: 821px) {
      justify-content: flex-start;
    }

    // min-height: 500px;

    &__page-title {
      @include heading2;
    }
  }
  .logo-image-signup {
    text-align: center;
    padding-top: 50px;

    // margin-bottom: 15px;
  }
  &__card {
    background-color: #fff;
    width: 433px;
    display: flex;
    flex-direction: column;
    padding: 2em;
    padding-bottom: 15px;
    border-radius: 25px;
    box-shadow: 0 17px 24px 0 rgba(0, 126, 191, 0.26);
  }

  &__fp-title {
    // @include heading1;
    // color: #261d56;
    font-size: 36px;
    font-weight: 500;
    justify-content: center;
    display: flex;
    margin-bottom: 50px;
    margin-top: 40px;
    text-align: center;

    @media screen and (max-width: 821px) {
      font-size: 30px;
    }

    @media (max-height: 685px) {
      font-size: 24px;
      margin: 30px 0;
    }
  }

  &__or {
    @include para1;
    color: #261d56;
    font-weight: 700;
    justify-content: center;
    display: flex;
    cursor: pointer;
  }
  &__btn {
    margin-top: 20px !important;
    min-width: 111px !important;
    color: #fff;

    transition: 0.3s;
    &:hover:enabled {
      color: white;
    }
  }

  &_back_btn {
    transition: 0.3s;
    &:hover:enabled {
      color: #007ebf;
    }
  }

  &__footer {
    @include heading3;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0e3276;
    color: #fff;
    &-text {
      margin-left: 20rem;
    }
  }
  &__logo {
    max-height: 30px;
    margin: 40px;
  }
  @media only screen and (max-width: 726px) {
    &__card {
      width: 95%;
      padding: 20px;
      box-shadow: 1px 1px 9px 1px #fff;
    }
    &__body {
      padding: 0;
      height: 100%;
    }
    &__header {
      padding: 10px;
    }
    &__subtitle {
      height: 90%;
    }
    &__companyName {
      width: 168px;
      padding: 10px;
    }
    &__header-title {
      display: none;
    }
    &__logo {
      width: 100px;
    }
  }
}

.MuiGrid-container {
  @media screen and (max-width: 821px) {
    display: flex;
    justify-content: center;
  }
  overflow: hidden;
}

.MuiGrid-grid-xs-6 {
  @media screen and (max-width: 821px) {
    max-width: 100vw !important;
    flex-basis: 100% !important;
  }
  @media screen and (max-width: 450px) {
    max-width: 100vw !important;
    flex-basis: 100% !important;
  }
  overflow: hidden;
}

// .MuiGrid-grid-xs-6 > * {
//   @media screen and (max-width: 821px) {
//     overflow: hidden !important;
//   }
//   @media screen and (max-width: 450px) {
//     overflow: hidden !important;
//   }
//   // overflow: hidden;
// }

.Signup__card {
  @media screen and (max-width: 821px) {
    @include signUp_card_mobile_style(80%, 2em);
  }
  @media screen and (max-width: 450px) {
    @include signUp_card_mobile_style(90%, 20px);
  }
}

.Login__card {
  @media screen and (max-width: 821px) {
    @include signUp_card_mobile_style(80%, 2em);
  }
  @media screen and (max-width: 450px) {
    @include signUp_card_mobile_style(90%, 20px);
  }
}
.Signup__body {
  @media screen and (max-width: 821px) {
    height: 80vh;
    margin-top: 0px;
    padding: 0px 10px;
  }
  @media screen and (max-width: 450px) {
    height: 80vh;
    margin-top: 0px;
    padding: 0px 10px;
  }
}
.Login__body {
  @media screen and (max-width: 821px) {
    height: 80vh;
    margin-top: 0px;
    padding: 0px 10px;
  }
  @media screen and (max-width: 450px) {
    height: 80vh;
    margin-top: 0px;
    padding: 0px 10px;
  }
}
.SendOtp__card {
  @media screen and (max-width: 821px) {
    @include signUp_card_mobile_style(80%, 2em);
    padding: 0px 10px;
  }
  @media screen and (max-width: 450px) {
    @include signUp_card_mobile_style(90%, 20px);
    padding: 0px 10px;
  }
}
