@import "../../utils/mixins.scss";

.EditProfile {
  width: 100%;
  margin-top: 70px;
  height: 90vh;
  background: var(--primary-background);
  &__paper {
    background-color: #fff;
    width: 90%;
    margin: auto;
    // max-height: calc(80vh - 100px);
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    // padding: 3em;
    padding-bottom: 15px;
    border-radius: 25px;
    box-shadow: 0 17px 24px 0 rgba(0, 126, 191, 0.26);
  }
  &__page-title {
    padding: 13px 20px;
    background-color: #eff2f7;
    padding-left: 40px;
    font-size: "22px";
  }
  &__profile-picture-container {
    position: relative;
    text-align: center;
    & img {
      border-radius: 100px;
      width: 166px;
      // margin-left: 120px;
      max-width: 166px;
      max-height: 166px;
      min-height: 166px;
      min-width: 166px;
      // margin-bottom: 15px;
    }
    &__upload-input {
      display: none;
      margin-left: 120px;
    }
    &__camera {
      transform: translate(-50%, -50%);
      position: absolute;
      left: 50%;
      top: 49%;
      width: 166px;
      height: 166px;
      background: #484848;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--white-color);
      opacity: 0.6;
    }
  }
  &__transportIcons-container {
    &__transport-icon {
      border: 2px solid #edeeef;
      border-radius: 4px;
      // padding: 45px 30px;
      // width: 40px;
      // height: 35px;
      padding: 21px;
      width: 56px;
      height: 56px;
      margin-bottom: 10px;
      position: relative;
      &__selected {
        border: 2px solid var(--primary-theme);
      }
    }
    &__transport-icon:not(:first-child) {
      margin-left: 10px;
    }
  }
  &__chips {
    &__tagInputField {
      margin-top: 0px;
      margin-bottom: 10px;
    }
  }
  // &__dropdown {
  //   .MuiInputLabel-outlined {
  //     background-color: var(--page-bg-color) !important;
  //   }
  // }
}

.editBasicInfo__form__container {
  padding-left: 28px;
  @media screen and (max-width: 821px) {
    padding-left: 0px;
  }
  @media screen and (max-width: 450px) {
    padding-left: 0px;
  }
}

.input__container {
  @media screen and (max-width: 821px) {
    max-width: 80% !important;
    flex-basis: 85% !important;
  }
  @media screen and (max-width: 450px) {
    max-width: 90% !important;
    flex-basis: 90% !important;
  }
}

.editProfile__input__section {
  @media screen and (max-width: 821px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .input__container {
      @media screen and (max-width: 821px) {
        order: 1;
      }
    }
    .input__container_1 {
      @media screen and (max-width: 821px) {
        order: 0;
      }
    }
    .input__container_2 {
      @media screen and (max-width: 821px) {
        order: 2;
      }
    }
  }
}

.EditProfile__paper {
  height: auto;
  // max-height: calc(88vh - 100px);
}
